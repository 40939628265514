.autocomplete [hidden] {
    display: none;
}

.autocomplete .visually-hidden {
    position: absolute;
    clip: rect(0, 0, 0, 0);
}

.autocomplete {
    display: inline-block;
    position: relative;
}

.autocomplete > input {
    display: block;
}

.autocomplete > ul {
    position: absolute;
    left: 0;
    z-index: 1;
    min-width: 100%;
    box-sizing: border-box;
    list-style: none;
    padding: 0;
    margin: 0;
    background: #fff;
}

.autocomplete > ul:empty {
    display: none;
}

.autocomplete > ul {
	border-radius: .3em;
	margin: .2em 0 0;
	background: hsla(0,0%,100%,.9);
	background: linear-gradient(to bottom right, white, hsla(0,0%,100%,.8));
	border: 1px solid rgba(0,0,0,.3);
	box-shadow: .05em .2em .6em rgba(0,0,0,.2);
	text-shadow: none;
}

@supports (transform: scale(0)) {
	.autocomplete > ul {
		transition: .3s cubic-bezier(.4,.2,.5,1.4);
		transform-origin: 1.43em -.43em;
	}
	
	.autocomplete > ul[hidden],
	.autocomplete > ul:empty {
		opacity: 0;
		transform: scale(0);
		display: block;
		transition-timing-function: ease;
	}
}

	/* Pointer */
	/*.autocomplete > ul:before {
		content: "";
		position: absolute;
		top: -.43em;
		left: 1em;
		width: 0; height: 0;
		padding: .4em;
		background: white;
		border: inherit;
		border-right: 0;
		border-bottom: 0;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
	}

	.autocomplete > ul > li {
		position: relative;
		padding: .2em .5em;
		cursor: pointer;
	}
	
	.autocomplete > ul > li:hover {
		background: hsl(200, 40%, 80%);
		color: black;
	}
	
	.autocomplete > ul > li[aria-selected="true"] {
		background: hsl(205, 40%, 40%);
		color: white;
	}*/
	
		.autocomplete mark {
			background: #c7e3ff;
		}
		
		.autocomplete li:hover mark {
			background: #5ca1e8;
		}
		
		.autocomplete li[aria-selected="true"] mark {
			background: #5ca1e8;
			color: inherit;
		}

input::-webkit-calendar-picker-indicator {
  display: none;
}
.autocomplete, .autocomplete-s {
	width: 20%;
}
.autocomplete-inner {
	width: 100%;
}
.autocomplete-secondary {
    width: 55%;
    display: inline-block !important;
}
.autocomplete-rate-primary {
    width: 10%;
    display: inline-block !important;
}
.autocomplete-rate-secondary {
    width: 10%;
    display: inline-block !important;
}

/*label[for="newListingVariable2Input1Short"], #newListingPriceCalculation1 {
	width: 20%;
    display: inline-block;
}
label[for="newListingVariable2Input1Long"], #newListingPriceCalculation2 {
	width: 55%;
    display: inline-block;
}
label[for="newListingVariable2Input1RatePrimary"], #newListingPriceCalculation3 {
	width: 10%;
    display: inline-block;
}
label[for="newListingVariable2Input1RateSecondary"], #newListingPriceCalculation4 {
	width: 10%;
    display: inline-block;
}*/
.listingVariableInfoPosition1 {
	width: 20%;
    display: inline-block !important;
}
.listingVariableInfoPosition2 {
	width: 55%;
    display: inline-block !important;
}
.listingVariableInfoPosition3 {
	width: 10%;
    display: inline-block !important;
}
.listingVariableInfoPosition4 {
	width: 10%;
    display: inline-block !important;
}

.autocomplete ul {
    position: absolute;
    width: 500%;
    background-color: #fff;
    padding: 6px;
}
.autocomplete ul li {
    border-bottom: 1px solid #e2e2e2;
    padding: 9px 0;
}
.autocomplete ul li:last-child {
    border-bottom: none;
    padding: 9px 0;
}
